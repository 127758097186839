import { useForm } from "react-hook-form";
import AdminLayout from "../../../components/Layout/Admin";
import Input from "../../../components/Form/Input";

interface FormValues {
  user: string;
  phone: string;
}

export default function AccountManage() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      user: "",
      phone: "",
    },
  });

  async function handleUpdateAccountConfig(data: FormValues): Promise<void> {
    console.log(data);
  }

  return (
    <AdminLayout>
      <div className="container-fluid">
        <h2>Gerenciamento da conta</h2>
        <p>Alterar as configurações da conta</p>

        <div className="mt-5">
          <h3 className="mb-3">Perfil</h3>

          <form onSubmit={handleSubmit(handleUpdateAccountConfig)}>
            <div className="form-group col-md-6 mb-3">
              <label htmlFor="user" className="mb-1">
                Usuário:
              </label>
              <Input
                control={control}
                errors={errors}
                type="text"
                name="user"
                disabled
                validation={{ required: "Campo obrigatório" }}
              />
            </div>

            <div className="form-group col-md-6 mb-3">
              <label htmlFor="phone" className="mb-1">
                Telefone
              </label>
              <Input
                mask="(99) 99999-9999"
                maskChar="_"
                control={control}
                errors={errors}
                type="text"
                name="phone"
                validation={{ required: "Campo obrigatório" }}
                placeholder="(__) _____-____"
              />
            </div>
            <button className="btn btn-primary">Salvar</button>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
}
