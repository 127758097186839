import { FaEnvelope, FaFacebook, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import PostModel from "../interfaces/models/post.model";
import { useCheckAuth } from "../hooks/useCheckAuth";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

interface IconShareProps {
  post: PostModel;
}

export default function IconShare({ post }: IconShareProps) {
  const isAuth: boolean = useCheckAuth();

  return (
    <div className="dropdown">
      <button
        className="bg-light shadow d-flex justify-content-center align-items-center rounded-circle p-2 mx-2"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <IoShareSocialOutline className="primary-color" />
      </button>
      <ul
        className="dropdown-menu text-gilroy-semibold"
        aria-labelledby="dropdownMenuButton"
      >
        <li className="link-dropdown link-twiiter">
          <Link
            to={
              isAuth
                ? `https://twitter.com/intent/tweet?text=Veja o item ${
                  encodeURIComponent(post.title ?? "")
                  } - ${encodeURIComponent(post.cidade ?? "")} ${
                    window.location.href
                  } Baixe o IdadeAtiva para não perder nenhum item`
                : "/login"
            }
            target={isAuth ? "_blank" : "_self"}
            rel="noreferrer"
            className="dropdown-item d-flex align-items-center justify-content-center"
            /*onClick={() => {
              if (!isAuth) {
                toast.error("Você deve realizar o login para compartilhar.");
              }
            }}*/
          >
            <FaXTwitter className="text-white" />
            <span className="mx-2 text-white">Compartilhar no Twitter</span>
          </Link>
        </li>
        <li className="link-dropdown link-linkedin">
          <Link
            to={
              isAuth
                ? `https://www.linkedin.com/shareArticle?mini=true&url=${
                    window.location.origin + `/posts/${post.id}`
                  }&quote=Veja a oportunidade ${post.title} - ${post.cidade} ${window.location.href} Baixe o IdadeAtiva para não perder nenhum item`
                : "/login"
            }
            target={isAuth ? "_blank" : "_self"}
            rel="noreferrer"
            className="dropdown-item d-flex align-items-center justify-content-center"
            /*onClick={() => {
              if (!isAuth) {
                toast.error("Você deve realizar o login para compartilhar.");
              }
            }}*/
          >
            <FaLinkedin />
            <span className="mx-2">Compartilhar no Linkedin</span>
          </Link>
        </li>
        <li className="link-dropdown link-facebook">
          <Link
            className="dropdown-item d-flex align-items-center justify-content-center"
            to={
              isAuth
                ? `https://www.facebook.com/sharer/sharer.php?u="${
                    window.location.origin + `/posts/${post.id}`
                  }`
                : "/login"
            }
            target={isAuth ? "_blank" : "_self"}
            rel="noreferrer"
            /*onClick={() => {
              if (!isAuth) {
                toast.error("Você deve realizar o login para compartilhar.");
              }
            }}*/
          >
            <FaFacebook />
            <span className="mx-2">Compartilhar no Facebook</span>
          </Link>
        </li>
        <li className="link-dropdown link-email">
          <Link
            to={
              isAuth
                ? `mailto:?body=Veja o item ${post.title} - ${post.cidade} ${
                    window.location.href
                  } Baixe o IdadeAtiva para não perder nenhum item`
                : "/login"
            }
            target={isAuth ? "_blank" : "_self"}
            rel="noreferrer"
            className="dropdown-item d-flex align-items-center justify-content-center"
            /*onClick={() => {
              if (!isAuth) {
                toast.error("Você deve realizar o login para compartilhar.");
              }
            }}*/
          >
            <FaEnvelope />
            <span className="mx-2">Compartilhar via Email</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
