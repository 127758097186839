import { FaEnvelope } from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";
import CleanLayout from "../../components/Layout/Clean";
import { SocialButtons } from "../../components/social-buttons";
import { BackLink } from "./components/back-link";
import { Form } from "./components/form";

export default function Register() {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryForm = searchParams.get("form");

  function handleRegisterWithEmail() {
    setSearchParams((state) => {
      if (queryForm) {
        state.delete("form");
      } else {
        state.set("form", "email");
      }

      return state;
    });
  }

  return (
    <CleanLayout title="Faça seu login aqui para começar!">
      <SocialButtons />

      <div className="row g-0 w-100 mt-4 mb-2">
        <h2 className="or-with-email">
          <span className="col-3 font-weight-semibold font-size-16 text-primary-opacity-50">
            ou
          </span>
        </h2>
      </div>

      {queryForm === "email" ? (
        <Form />
      ) : (
        <button
          onClick={handleRegisterWithEmail}
          type="button"
          className="btn btn-blue-login w-100 mb-4"
        >
          <span className="align-middle">Cadastrar-se com e-mail</span>
          <FaEnvelope />
        </button>
      )}

      <div className="col-md-6 d-flex flex-column justify-content-center align-items-center mb-4">
        <p
          className="m-0 text-center text-login"
          style={{
            fontSize: 11,
          }}
        >
          Criando uma conta você concorda com nossos{" "}
          <Link to="/privacy" className="link-blue">
            termos de serviço, política de privacidade e nossas configurações de
            notificação.
          </Link>
        </p>
      </div>

      {queryForm ? (
        <BackLink text="Voltar para formas de cadastro" href="/register" />
      ) : (
        <BackLink text="Voltar para login" href="/login" />
      )}
    </CleanLayout>
  );
}
