// @ts-ignore

import clsx from "clsx";
import ReactInputMask from "react-input-mask";

import { ComponentProps } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";

interface Props extends Omit<ComponentProps<"input">, "ref"> {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  mask?: string;
  maskChar?: string;
  validation: RegisterOptions;
  showErrorMsg?: boolean;
}

const Input: React.FC<Props> = ({
  className = "",
  control,
  errors,
  name,
  mask = "",
  maskChar = "",
  validation,
  showErrorMsg = true,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field }) => (
        <>
          <ReactInputMask
            id={field.name}
            name={field.name}
            mask={mask}
            maskChar={maskChar}
            className={clsx(
              `${className} form-control`,
              errors[name] && "border border-danger text-danger",
            )}
            onChange={field.onChange}
            value={field.value}
            {...rest}
          />
          {showErrorMsg && errors[name] && (
            <small className="text-danger">
              {errors[name]?.message?.toString()}
            </small>
          )}
        </>
      )}
    />
  );
};

export default Input;
