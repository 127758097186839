import { useEffect, useState } from "react";
import { FaRegStar, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LuStarOff } from "react-icons/lu";
import {
  getFavorites,
  getPostsFeatured,
  postSwapFavorite,
} from "../../../services/posts.service";
import PostModel from "../../../interfaces/models/post.model";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";
import toast from "react-hot-toast";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import { getAdmin } from "../../../services/admin.service";

export const OfertasDestaque = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const queryString = searchParams.get("query");

  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<PostModel[]>([]);

  const [postsFavorites, setPostFavorites] = useState<PostModel[]>([]);

  const localStorageKey = "featuredPosts";
  const localStorageFavoritesKey = "favoritesPosts";

  const saveToLocalStorageFeatured = (data: PostModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const saveToLocalStorageFavorites = (data: PostModel[]) => {
    localStorage.setItem(localStorageFavoritesKey, JSON.stringify(data));
  };

  const loadFromLocalStorageFeatured = (): PostModel[] => {
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : [];
  };

  const loadFromLocalStorageFavorites = (): PostModel[] => {
    const data = localStorage.getItem(localStorageFavoritesKey);
    return data ? JSON.parse(data) : [];
  };

  async function fetchPostsFeatured(): Promise<void> {
    setLoading(true);

    getPostsFeatured(queryString)
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorageFeatured();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorageFeatured(fetchedPosts);
          setPosts(fetchedPosts);
        } else {
          setPosts(storedPosts);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const storedPosts = loadFromLocalStorageFeatured();
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
    }

    fetchPostsFeatured();
  }, [queryString]);

  async function fetchFavorites(): Promise<void> {
    setLoading(true);

    getFavorites()
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorageFavorites();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorageFavorites(fetchedPosts);
          setPostFavorites(fetchedPosts);
        } else {
          setPostFavorites(storedPosts);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar os favoritos.");
      })
      .finally(() => setLoading(false));
  }

  async function handleFavoritePost(postId: string): Promise<void> {
    setLoading(true);

    if (!isAuth) {
      toast.error("Você deve fazer login para acessar essa função!");
      navigate("/login");
      return;
    }

    postSwapFavorite(postId)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        fetchFavorites();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao favoritar");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (isAuth) {
      const storedPosts = loadFromLocalStorageFavorites();
      if (storedPosts.length > 0) {
        setPostFavorites(storedPosts);
      }

      fetchFavorites();
    }
  }, []);

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <div className="ofertas-destaque">
      <div className="d-flex align-items-center flex-column-reverse flex-md-row justify-content-md-between mb-5">
        <div className="d-flex align-items-center flex-column justify-content-center flex-md-row justify-content-md-start flex-wrap">
          <h4 className="color-primary text-gilroy-semibold">Em destaque</h4>
          {/*<p className="color-secondary mx-3 mb-0 text-gilroy-semibold text-subtitle">
            Postagens que estão sendo mais aguardadas
          </p> */}
        </div>
        <div className="mb-3">
          {queryString && (
            <button
              className="btn btn-danger"
              onClick={() => {
                setSearchParams({
                  query: "",
                });
              }}
            >
              Limpar pesquisa
            </button>
          )}
        </div>
      </div>

      <div className="row mb-4">
        {loading && <Loading center />}

        {!loading && posts.length === 0 && (
          <p className="color-secondary text-gilroy-semibold h5 text-center text-md-start">
            Aguarde novidades.
          </p>
        )}

        {!loading &&
          posts.map((post) => (
            <div
              key={post.id}
              className="col-6 col-sm-6 col-md-4 col-lg-3 mt-30 position-relative"
              style={{ marginBottom: "80px" }}
            >
              <div className="mb-1">
                <div
                  className={`badge ${
                    postsFavorites
                      .map((favoritePosts) => favoritePosts.id)
                      .includes(post.id)
                      ? "bg-azul border-favorites"
                      : "bg-white border-favorites-null"
                  } position-absolute text-center`}
                >
                  <div className="w-100" style={{ maxWidth: 150 }}>
                    <div className="d-flex align-items-center">
                      {postsFavorites
                        .map((favoritePost) => favoritePost.id)
                        .includes(post.id) ? (
                        <FaRegStar
                          style={{ fontSize: "25px" }}
                          id="favoritos"
                          className="cursor-pointer text-white"
                          onClick={() => handleFavoritePost(post.id)}
                        />
                      ) : (
                        <LuStarOff
                          style={{ color: "#C5CDE5", fontSize: "25px" }}
                          id="favoritos"
                          className="cursor-pointer"
                          onClick={() => handleFavoritePost(post.id)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Link to={`/posts/${post.id}`}>
                  <img
                    src={post.poster}
                    className="config-image"
                    alt="Imagem"
                  />
                </Link>
                <div>
                  <img
                    src={`/images/categories/${post.category?.systemKey}_up.svg`}
                    className="img-fluid mt-n1"
                    style={{ marginTop: "-20px" }}
                    alt={post.category?.title}
                  />
                </div>
                <div className="d-flex icons-category justify-content-end">
                  <a
                    href={`https://api.whatsapp.com/send?text=Veja o item ${post.title} - ${post.cidade} ${window.location.href} Baixe o IdadeAtiva para não perder nenhum item`}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                  >
                    <FaWhatsapp className="text-white" />
                  </a>
                  <IconShare post={post} />
                </div>
                <div className="text-card mt-2">
                  <Link
                    to={`/posts/${post.id}`}
                    className="text-black fw-semibold text-md-start text-gilroy-semibold"
                    style={{ marginBottom: "-2px" }}
                  >
                    {post.title}
                  </Link>
                  <div
                    className="d-flex align-items-center"
                    style={{ fontSize: "13px" }}
                  >
                    <IoLocationSharp className="icon-color" />
                    <p className="mb-0 mx-1 text-light-emphasis fw-semibold text-gilroy-semibold">
                      {post.cidade}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="border mb-4"></div>
    </div>
  );
};
