import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/Main";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { useState } from "react";
import UserModel from "../../interfaces/models/user.model";
import { deleteUser } from "../../services/user.service";
import toast from "react-hot-toast";
import Modal from "../../components/Modal";
import { Loading } from "../../components/Loading";

export default function About() {
  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState<boolean>(false);
  const [perfil, setPerfil] = useState<UserModel>({} as UserModel);

  function handleModalDeleteOpen(perfil: UserModel) {
    setPerfil(perfil);
    setIsModalDeleteOpen(true);
  }

  function handleModalDeleteClose() {
    setIsModalDeleteOpen(false);
    setPerfil({} as UserModel);
  }

  function logout(): void {
    localStorage.clear();
    navigate("/");
  }

  async function handleDeletePerfil(): Promise<void> {
    setLoading(true);

    deleteUser()
      .then(() => {
        toast.success("Perfil deletado com sucesso!");
        logout();
      })
      .catch((err) => {
        toast.error(err.response.data[0] ?? "Erro ao deletar perfil");
      })
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout background="transparent">
      <div className="container-xl text-gilroy-semibold">
        <p className="title-about mb-4">Sobre o IdadeAtiva</p>
        <p className="description-about">
          Bem-vindo ao IdadeAtiva, o aplicativo especialmente pensado para quem
          deseja abraçar as ricas possibilidades que cada novo dia traz. Com o
          IdadeAtiva, você pode explorar e se beneficiar de uma ampla variedade
          de recursos que enaltecem a sabedoria e a vivência acumulada ao longo
          dos anos. É o seu companheiro ideal para uma vida plena e vibrante,
          oferecendo acesso a tudo o que você precisa para aproveitar ao máximo
          cada momento.
        </p>
        <p className="description-about">Categorias e Recursos:</p>
        <p className="description-about">
          Cuidado: Descubra serviços e orientações para manter o bem-estar
          pessoal no seu dia a dia, com dicas que valorizam sua rotina.
        </p>
        <p className="description-about">
          Dinheiro: Acesse conselhos financeiros e ferramentas para
          gerenciamento de suas finanças pessoais de forma simples e segura.
        </p>
        <p className="description-about">
          Entretenimento: Explore atividades culturais e de lazer que celebram a
          experiência e a alegria de viver.
        </p>
        <p className="description-about">
          Estilo: Dicas de estilo e beleza que ressaltam sua elegância natural e
          valorizam sua individualidade.
        </p>
        <p className="description-about">
          Eventos: Mantenha-se informado sobre eventos que valorizam a sabedoria
          e a troca de experiências entre gerações.
        </p>
        <p className="description-about">
          Família: Recursos para fortalecer os laços familiares e aproveitar ao
          máximo o tempo com quem você ama.
        </p>
        <p className="description-about">
          Jogos: Jogos divertidos e estimulantes, ideais para manter a mente
          ativa e engajada.
        </p>
        <p className="description-about">
          Medicamentos: Informações confiáveis sobre medicação e gestão de saúde
          adaptadas às suas necessidades.
        </p>
        <p className="description-about">
          Relacionamento: Ferramentas para cultivar e manter relacionamentos
          sociais ricos e significativos.
        </p>
        <p className="description-about">
          Saúde: Dicas e orientações para uma vida saudável e ativa, com ênfase
          no bem-estar integral.
        </p>
        <p className="description-about">
          Seguro Social: Acesso a informações essenciais sobre benefícios e
          direitos, simplificando processos e esclarecendo dúvidas.
        </p>
        <p className="description-about">
          Tecnologia: Guias para ajudar você a se manter atualizado com as
          inovações tecnológicas, de forma fácil e acessível.
        </p>
        <p className="description-about">
          Trabalho: Oportunidades e recursos para quem deseja se manter ativo no
          mercado de trabalho ou explorar novas áreas.
        </p>
        <p className="description-about">
          Vida em Casa: Soluções para tornar seu lar um refúgio ainda mais
          confortável e acolhedor.
        </p>
        <p className="description-about">
          Viagem: Inspirações e dicas de viagem que consideram seu conforto e
          seus interesses, para que você explore o mundo ao seu ritmo.
        </p>
        <p className="description-about">
          Vídeos: Uma seleção de vídeos que educam, inspiram e entretem,
          refletindo os interesses e a sabedoria de uma vida plena.
        </p>
        <p className="description-about">
          Voluntário: Descubra como contribuir significativamente com a
          comunidade e se envolver em atividades que enriquecem o espírito.
        </p>
        <p className="description-about">
          O IdadeAtiva celebra cada fase da vida, proporcionando ferramentas
          para você continuar crescendo, aprendendo e desfrutando de tudo o que
          o mundo tem a oferecer. Comece hoje a sua jornada para uma vida ainda
          mais ativa e recompensadora!
        </p>

        {isAuth ? (
          <button
            className="btn btn-danger mt-2 rounded-pill"
            onClick={() => handleModalDeleteOpen(perfil)}
          >
            Excluir conta
          </button>
        ) : null}

        <div className="version mt-4">Versão 1.0.0.0 2024</div>
      </div>

      <Modal close={handleModalDeleteClose} isOpen={isModalDeleteOpen}>
        <div className="row d-flex justify-content-center align-items-center text-center">
          <p className="h5">Deseja excluir PERMANENTEMENTE a sua conta?</p>
          <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-evenly align-items-center mb-3 mt-3">
            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100 mb-3 mb-sm-0"
              style={{ width: 175 }}
              onClick={handleModalDeleteClose}
            >
              Cancelar
            </button>

            <button
              className="btn btn-danger rounded-pill px-3 py-2 h-100"
              style={{ width: 175 }}
              onClick={handleDeletePerfil}
            >
              {loading ? (
                <Loading />
              ) : (
                <span className="align-middle">Excluir</span>
              )}
            </button>
          </div>
        </div>
      </Modal>
    </MainLayout>
  );
}
