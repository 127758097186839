import PostArgs from "../interfaces/args/post.args";
import SettingsArgs from "../interfaces/args/settings.args";
import PostModel from "../interfaces/models/post.model";
import UserModel from "../interfaces/models/user.model";
import api from "./client/api-client";

export const checkAccess = async () => await api.get("/admin/checkaccess");

export const getPostsToApprove = async () =>
  await api.get<PostModel[]>("/admin/posts/toapprove");

export const approvePost = async (id: string) =>
  await api.put(`/admin/posts/${id}/approve`);

export const featurePost = async (id: string) =>
  await api.put(`/admin/posts/${id}/featured`);

export const removePost = async (id: string) =>
  await api.delete(`/admin/posts/${id}`);

export const getUsersAdmin = async () =>
  await api.get<UserModel[]>("/admin/users");

export const putStatusUser = async (userId: string) =>
  await api.put(`/admin/users/${userId}/status`);

export const putPosts = async (postId: string, args: PostArgs) =>
  await api.put(`/admin/posts/${postId}`, args);

export const postSettings = async (args: SettingsArgs) =>
  await api.post("/admin/settings", args);

export const getAdmin = async () => {
  return await api.get("/admin/dev/set-admin");
};
