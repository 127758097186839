import { FaArrowRight, FaRegStar, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../../../components/Layout/Main";
import { useEffect, useState } from "react";
import PostModel from "../../../interfaces/models/post.model";
import {
  getFavorites,
  getPostById,
  postSwapFavorite,
} from "../../../services/posts.service";
import toast from "react-hot-toast";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import { LuStarOff } from "react-icons/lu";

export default function PostDetails() {
  const { postId } = useParams();

  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [post, setPost] = useState<PostModel>({} as PostModel);

  const [postsFavorites, setPostsFavorites] = useState<PostModel[]>([]);

  async function fetchFavorites(): Promise<void> {
    setLoading(true);

    getFavorites()
      .then(({ data }) => setPostsFavorites(data))
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar.");
      })
      .finally(() => setLoading(false));
  }

  async function handleFavoritePost(postId: string): Promise<void> {
    setLoading(true);

    if (!isAuth) {
      toast.error("Você deve fazer login para acessar essa função!");
      navigate("/login");
      return;
    }

    postSwapFavorite(postId)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        fetchFavorites();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao favoritar");
      })
      .finally(() => setLoading(false));
  }

  async function fetchPostsById(): Promise<void> {
    setLoading(true);

    getPostById(postId!)
      .then(({ data }) => {
        setPost(data);
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchPostsById();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  useEffect(() => {
    if (isAuth) {
      fetchFavorites();
    }
  }, []);

  return (
    <MainLayout>
      {loading && <Loading center />}

      {!loading && (
        <div className="container">
          <div className="bg-white mb-4 details">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="position-relative"
                  style={{ marginBottom: "80px" }}
                >
                  <div className="mb-1">
                    <div
                      className={`badge-details ${
                        postsFavorites
                          .map((favoritePosts) => favoritePosts.id)
                          .includes(post.id)
                          ? "bg-azul border-favorites"
                          : "bg-white border-favorites-null"
                      } position-absolute text-center`}
                    >
                      <div className="w-100" style={{ maxWidth: 150 }}>
                        <div className="d-flex align-items-center">
                          {postsFavorites
                            .map((favoritePost) => favoritePost.id)
                            .includes(post.id) ? (
                            <FaRegStar
                              style={{ fontSize: "25px" }}
                              id="favoritos"
                              className="cursor-pointer text-white"
                              onClick={() => handleFavoritePost(post.id)}
                            />
                          ) : (
                            <LuStarOff
                              style={{ color: "#C5CDE5", fontSize: "25px" }}
                              id="favoritos"
                              className="cursor-pointer"
                              onClick={() => handleFavoritePost(post.id)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <img
                      src={post.poster}
                      className="img-details"
                      alt="Imagem"
                    />
                    <div>
                      <img
                        src={`/images/categories/${post.category?.systemKey}_up.svg`}
                        className="img-fluid mt-n1"
                        style={{ marginTop: "-20px" }}
                        alt={post.category?.title}
                      />
                    </div>
                    {/*<div className="d-flex icons-category justify-content-end">
                      <a
                        href={`https://api.whatsapp.com/send?text=Veja o item ${
                          post.title
                        } - ${
                          post.preco > 0
                            ? `R$ ${post.preco} ${post.pagamento}`
                            : post.pagamento
                        }! ${
                          window.location.href
                        } Baixe o IdadeAtiva para não perder nenhum item`}
                        target="_blank"
                        rel="noreferrer"
                        className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                      >
                        <FaWhatsapp className="text-white" />
                      </a>
                      <IconShare post={post} />
                      </div>*/}
                    <h3 className="text-dark-emphasis mb-1 mt-3 text-gilroy-semibold">
                      {post.title}
                    </h3>
                    <h4 className="mb-3">
                      <IoLocationSharp className="icon-color d-inline-block fs-5" />
                      <span className="text-body-secondary fs-6 mx-1 text-gilroy-semibold">
                        {post.cidade}
                      </span>
                    </h4>
                    {post.description && post.description.includes("\n") ? (
                      <pre
                        className="fs-6 text-body-secondary fw-semibold"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {post.description}
                      </pre>
                    ) : (
                      <p
                        style={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          display: "inline-block",
                        }}
                        className="text-body-secondary"
                      >
                        {post.description}
                      </p>
                    )}
                    <div className="mt-2">
                      {post.conteudoExtra01Titulo &&
                      post.conteudoExtra01 &&
                      post.conteudoExtra02Titulo &&
                      post.conteudoExtra02 &&
                      post.conteudoExtra03Titulo &&
                      post.conteudoExtra03 &&
                      post.conteudoExtra04Titulo &&
                      post.conteudoExtra04 ? (
                        <div className="d-flex flex-column">
                          {/* Conteúdo 01 */}
                          <span className="text-body-secondary fs-6 text-gilroy-semibold">
                            {post.conteudoExtra01Titulo}
                          </span>
                          <span className="text-body-secondary mt-1 mb-4">
                            {post.conteudoExtra01}
                          </span>
                          {/* Conteúdo 02 */}
                          <span className="text-body-secondary fs-6 text-gilroy-semibold">
                            {post.conteudoExtra02Titulo}
                          </span>
                          <span className="text-body-secondary mt-1 mb-4">
                            {post.conteudoExtra02}
                          </span>
                          {/* Conteúdo 03 */}
                          <span className="text-body-secondary fs-6 text-gilroy-semibold">
                            {post.conteudoExtra03Titulo}
                          </span>
                          <span className="text-body-secondary mt-1 mb-4">
                            {post.conteudoExtra03}
                          </span>
                          {/* Conteúdo 04 */}
                          <span className="text-body-secondary fs-6 text-gilroy-semibold">
                            {post.conteudoExtra04Titulo}
                          </span>
                          <span className="text-body-secondary mt-1 mb-2">
                            {post.conteudoExtra04}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <Link
                      to={isAuth ? post.link! : `/login?postId=${postId}`}
                      target={isAuth ? "_blank" : "_self"}
                      rel="noreferrer"
                      /*onClick={() => {
                        if (!isAuth) {
                          toast.error(
                            "Você deve realizar o login para acessar o link"
                          );
                        }
                      }}*/
                      className="btn btn-blue-details w-100 border-0 mt-3 mb-3"
                    >
                      <span className="text-gilroy-semibold">Entrar</span>
                      <FaArrowRight className="mt-1" />
                    </Link>
                    <div className="w-100 rounded border border-2 p-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <span
                          className="p-1 mx-2 text-gilroy-semibold"
                          style={{ color: "#16476a" }}
                        >
                          compartilhar
                        </span>
                        <div className="d-flex justify-content-end">
                          <a
                            href={`https://api.whatsapp.com/send?text=Veja o item ${post.title} - ${post.cidade} ${window.location.href} Baixe o IdadeAtiva para não perder nenhum item`}
                            target="_blank"
                            rel="noreferrer"
                            className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                          >
                            <FaWhatsapp className="text-white" />
                          </a>
                          <IconShare post={post} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
}
