import Header from "../Header";
import Footer from "../Footer";
import clsx from "clsx";

interface Props {
  children: React.ReactNode;
  background?: "white" | "transparent";
}

export default function MainLayout({ children, background = "white" }: Props) {
  return (
    <>
      <Header />
      <div
        className={clsx(
          "pt-5 container-border",
          background === "transparent" && "bg-transparent",
          background === "white" && "bg-white",
        )}
      >
        <div
          className={clsx(
            "pb-5 px-3 px-lg-4 min-vh-100",
            background === "transparent" && "bg-transparent",
            background === "white" && "mt-5 bg-white",
          )}
        >
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
}
