import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../../../components/Form/Input";
import { Loading } from "../../../../../components/Loading";
import PostArgs from "../../../../../interfaces/args/post.args";
import CategoryModel from "../../../../../interfaces/models/category.model";
import { putPosts } from "../../../../../services/admin.service";
import {
  getCategories,
  getPostById,
  uploadImage,
} from "../../../../../services/posts.service";

interface FormValues {
  title: string;
  description: string;
  //price: string;
  //paymentTerms: string;
  shop: string;
  link: string;
  conteudoExtra01Titulo: string | null;
  conteudoExtra01: string | null;
  conteudoExtra02Titulo: string | null;
  conteudoExtra02: string | null;
  conteudoExtra03Titulo: string | null;
  conteudoExtra03: string | null;
  conteudoExtra04Titulo: string | null;
  conteudoExtra04: string | null;
}

export const Form = () => {
  const { postId } = useParams();

  const navigate = useNavigate();

  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryModel>(
    {} as CategoryModel
  );
  const [imagePost, setImagePost] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: "",
      description: "",
      //price: "",
      //paymentTerms: "",
      shop: "",
      link: "",
      conteudoExtra01Titulo: "",
      conteudoExtra01: "",
      conteudoExtra02Titulo: "",
      conteudoExtra02: "",
      conteudoExtra03Titulo: "",
      conteudoExtra03: "",
      conteudoExtra04Titulo: "",
      conteudoExtra04: "",
    },
  });

  function handleUploadClick() {
    inputFileRef.current?.click();
  }

  function uploadImagePost(event: React.ChangeEvent<HTMLInputElement>) {
    setLoading(true);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();
      reader.onload = (e) => {
        const formData = new FormData();
        formData.append("file", file);

        uploadImage(formData)
          .then((res) => {
            setImagePost(res.data.url);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data[0] ??
                "Erro ao enviar imagem. Tente novamente."
            );
          })
          .finally(() => {
            setLoading(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }

  async function getCategoriesPost() {
    setLoading(true);

    getCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao buscar categorias");
      })
      .finally(() => setLoading(false));
  }

  async function handleEditPost(data: FormValues): Promise<void> {
    if (!imagePost) {
      toast.error("Selecione uma imagem para o post!");
      return;
    }

    let args: PostArgs = {
      approved: true,
      date: new Date().toISOString(),
      featured: true,
      rememberMe: true,
      rememberMeCount: 0,
      title: data.title,
      categoryId: selectedCategory.id,
      category: selectedCategory,
      description: data.description,
      poster: imagePost,
      preco: 0,
      pagamento: null,
      cidade: data.shop,
      link: data.link,
      platforms: null,
      conteudoExtra01Titulo: data.conteudoExtra01Titulo,
      conteudoExtra01: data.conteudoExtra01,
      conteudoExtra02Titulo: data.conteudoExtra02Titulo,
      conteudoExtra02: data.conteudoExtra02,
      conteudoExtra03Titulo: data.conteudoExtra03Titulo,
      conteudoExtra03: data.conteudoExtra03,
      conteudoExtra04Titulo: data.conteudoExtra04Titulo,
      conteudoExtra04: data.conteudoExtra04,
    };

    setLoading(true);

    putPosts(postId!, args)
      .then(() => {
        navigate("/admin/posts");
        toast.success("Post atualizado!");
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao atualizar post");
      })
      .finally(() => setLoading(false));
  }

  async function getPost(): Promise<void> {
    setLoading(true);

    getPostById(postId!)
      .then(({ data }) => {
        setImagePost(data.poster);
        setValue("title", data.title ?? "");
        setSelectedCategory(data.category!);
        setValue("description", data.description ?? "");
        setValue("shop", data.cidade ?? "");
        setValue("link", data.link ?? "");
        setValue("conteudoExtra01Titulo", data.conteudoExtra01Titulo ?? "");
        setValue("conteudoExtra01", data.conteudoExtra01 ?? "");
        setValue("conteudoExtra02Titulo", data.conteudoExtra02Titulo ?? "");
        setValue("conteudoExtra02", data.conteudoExtra02 ?? "");
        setValue("conteudoExtra03Titulo", data.conteudoExtra03Titulo ?? "");
        setValue("conteudoExtra03", data.conteudoExtra03 ?? "");
        setValue("conteudoExtra04Titulo", data.conteudoExtra04Titulo ?? "");
        setValue("conteudoExtra04", data.conteudoExtra04 ?? "");
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao buscar post");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getCategoriesPost();
    getPost();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className="form-cadastro-post"
      onSubmit={handleSubmit(handleEditPost)}
    >
      <label className="capa cursor-pointer">
        {/* Input file hidden */}
        <input
          ref={inputFileRef}
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          multiple={false}
          onChange={uploadImagePost}
          onClick={handleUploadClick}
        />

        <img src={imagePost || `/images/no-picture.png`} alt="Capa do post" />
        <span>Imagem de capa</span>
      </label>

      <div className="divider mt-5"></div>

      <div className="row g-3 px-3 px-md-5 py-5">
        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="title" className="cadastro-post-label mb-2">
              Título
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="title"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.title && (
            <small className="text-danger">
              {errors.title?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon arrow-right">
            <label htmlFor="category" className="mb-2 cadastro-post-label">
              Categoria
            </label>
            <select
              value={selectedCategory.id}
              onChange={(e) => {
                setSelectedCategory(
                  categories.find(
                    (c) => c.id === e.target.value
                  ) as CategoryModel
                );
              }}
              className="cadastro-post-input"
            >
              <option disabled value="">
                Selecione uma categoria
              </option>
              {categories.map((category) => (
                <option
                  disabled={!category.id}
                  key={category.id}
                  value={category.id}
                >
                  {category.title}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Cidade
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="shop"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.shop && (
            <small className="text-danger">
              {errors.shop?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon icon-textarea edit">
            <label htmlFor="description" className="mb-2 cadastro-post-label">
              Descrição
            </label>
            <textarea
              {...register("description", { required: "Campo obrigatório" })}
              rows={6}
              className={clsx(
                "cadastro-post-textarea",
                errors.description && "border border-danger text-danger"
              )}
              placeholder="Insira sua descrição mais detalhada possível."
            ></textarea>
            {errors.description && (
              <small className="text-danger">
                {errors.description?.message?.toString()}
              </small>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Título 1 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra01Titulo"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra01Titulo && (
            <small className="text-danger">
              {errors.conteudoExtra01Titulo?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Descrição 1 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra01"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra01 && (
            <small className="text-danger">
              {errors.conteudoExtra01?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Título 2 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra02Titulo"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra02Titulo && (
            <small className="text-danger">
              {errors.conteudoExtra02Titulo?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Descrição 2 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra02"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra02 && (
            <small className="text-danger">
              {errors.conteudoExtra02?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Título 3 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra03Titulo"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra03Titulo && (
            <small className="text-danger">
              {errors.conteudoExtra03Titulo?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Descrição 3 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra03"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra03 && (
            <small className="text-danger">
              {errors.conteudoExtra03?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Título 4 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra04Titulo"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra04Titulo && (
            <small className="text-danger">
              {errors.conteudoExtra04Titulo?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="shop" className="cadastro-post-label mb-2">
              Descrição 4 (opcional)
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="conteudoExtra04"
              validation={{}}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.conteudoExtra04 && (
            <small className="text-danger">
              {errors.conteudoExtra04?.message?.toString()}
            </small>
          )}
        </div>

        <div className="col-12">
          <div className="form-group d-flex flex-column icon edit">
            <label htmlFor="link" className="cadastro-post-label mb-2">
              Link
            </label>
            <Input
              showErrorMsg={false}
              control={control}
              errors={errors}
              name="link"
              validation={{ required: "Campo obrigatório" }}
              type="text"
              className="cadastro-post-input"
            />
          </div>
          {errors.link && (
            <small className="text-danger">
              {errors.link?.message?.toString()}
            </small>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center footer-cadastro-post">
        <button
          type={loading ? "button" : "submit"}
          className="btn btn-cadastro-post"
          disabled={loading}
        >
          {loading ? (
            <Loading />
          ) : (
            <span className="align-middle text-gilroy-semibold">Salvar configurações</span>
          )}
        </button>
      </div>
    </form>
  );
};
