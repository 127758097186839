import { FaRegStar, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import MainLayout from "../../../components/Layout/Main";
import { Filtro } from "../components/filtro";
import { useEffect, useState } from "react";
import PostModel from "../../../interfaces/models/post.model";
import {
  getCategories,
  getFavorites,
  getPostsByCategory,
  postSwapFavorite,
} from "../../../services/posts.service";
import toast from "react-hot-toast";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import { LuStarOff } from "react-icons/lu";

export default function PostsByCategory() {
  const { categoryId } = useParams();

  const isAuth: boolean = useCheckAuth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<string>("Todas categorias");

  const emptyGuid = "00000000-0000-0000-0000-000000000000";

  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  const [postsFavorites, setPostsFavorites] = useState<PostModel[]>([]);

  const localStorageKey = "postsByCategory";
  const localStorageFavoritesKey = "postsFavoritesByCategory";

  const saveToLocalStorageFeatured = (data: PostModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const saveToLocalStorageFavorites = (data: PostModel[]) => {
    localStorage.setItem(localStorageFavoritesKey, JSON.stringify(data));
  };

  const loadFromLocalStorageFeatured = (): PostModel[] => {
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : [];
  };

  const loadFromLocalStorageFavorites = (): PostModel[] => {
    const data = localStorage.getItem(localStorageFavoritesKey);
    return data ? JSON.parse(data) : [];
  };

  async function fetchFavorites(): Promise<void> {
    setLoading(true);

    getFavorites()
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorageFavorites();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorageFavorites(fetchedPosts);
          setPostsFavorites(fetchedPosts);
        } else {
          setPostsFavorites(storedPosts);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar.");
      })
      .finally(() => setLoading(false));
  }

  async function handleFavoritePost(postId: string): Promise<void> {
    setLoading(true);

    if (!isAuth) {
      toast.error("Você deve fazer login para acessar essa função!");
      navigate("/login");
      return;
    }

    postSwapFavorite(postId)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        fetchFavorites();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao favoritar");
      })
      .finally(() => setLoading(false));
  }

  async function fetchPosts(): Promise<void> {
    setLoading(true);

    getPostsByCategory(queryString, categoryId)
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorageFeatured();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorageFeatured(fetchedPosts);
          setPosts(fetchedPosts);
        } else {
          setPosts(storedPosts);
        }
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  async function fetchCategories(): Promise<void> {
    setLoading(true);

    getCategories()
      .then(({ data }) => {
        setSelectedCategory(
          data.find((c) => c.id === categoryId)?.title || "Desconhecida"
        );
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (categoryId === emptyGuid) {
      setSelectedCategory("Todas categorias");
    } else {
      fetchCategories();
    }

    const storedPosts = loadFromLocalStorageFeatured();
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
    }

    fetchPosts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, categoryId]);

  useEffect(() => {
    if (isAuth) {
      const storedPosts = loadFromLocalStorageFavorites();
      if (storedPosts.length > 0) {
        setPostsFavorites(storedPosts);
      }

      fetchFavorites();
    }
  }, []);

  return (
    <MainLayout>
      <div className="container mw-100">
        <div className="row">
          <div className="col-md-3">
            <Filtro />
          </div>

          <div className="col-md-9">
            <div className="d-flex align-items-center flex-column flex-md-row mb-5">
              <h4 className="color-primary fw-semibold text-gilroy-semibold">
                {categoryId === emptyGuid ? "Exibindo:" : "Categoria:"}
              </h4>
              <h4 className="text-black fw-semibold mx-2 text-gilroy-semibold">
                {!loading && selectedCategory}
              </h4>
            </div>

            <div className="row mb-4">
              {loading && <Loading center />}

              {!loading && posts.length === 0 && (
                <p className="color-secondary text-gilroy-semibold h5 text-center text-md-start">
                  Em breve.
                </p>
              )}

              {/*!loading && posts.length === 0 && <NoItemsFound />*/}

              {!loading &&
                posts.map((post) => (
                  <div
                    key={post.id}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 col-sm-6 mt-30 position-relative"
                  >
                    <div className="mb-0">
                      <div
                        className={`badge ${
                          postsFavorites
                            .map((favoritePosts) => favoritePosts.id)
                            .includes(post.id)
                            ? "bg-azul border-favorites"
                            : "bg-white border-favorites-null"
                        } position-absolute text-center`}
                      >
                        <div className="w-100" style={{ maxWidth: 150 }}>
                          <div className="d-flex align-items-center">
                            {postsFavorites
                              .map((favoritePost) => favoritePost.id)
                              .includes(post.id) ? (
                              <FaRegStar
                                style={{ fontSize: "25px" }}
                                id="favoritos"
                                className="cursor-pointer text-white"
                                onClick={() => handleFavoritePost(post.id)}
                              />
                            ) : (
                              <LuStarOff
                                style={{ color: "#C5CDE5", fontSize: "25px" }}
                                id="favoritos"
                                className="cursor-pointer"
                                onClick={() => handleFavoritePost(post.id)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <Link to={`/posts/${post.id}`}>
                        <img
                          src={post.poster}
                          className="img-fluid img-poster cursor-pointer"
                          alt="Imagem"
                        />
                      </Link>
                      <img
                        src={`/images/categories/${post.category?.systemKey}_up.png`}
                        className="img-fluid mt-n1"
                        style={{ marginTop: "-20px" }}
                        alt={post.category?.title}
                      />
                      <div
                        className="d-flex icons-category justify-content-end mb-2"
                        /*style={{ marginRight: "-25px" }}*/
                      >
                        <a
                          href={`https://api.whatsapp.com/send?text=Veja o item ${post.title} - ${post.cidade} ${window.location.href} Baixe o IdadeAtiva para não perder nenhum item`}
                          target="_blank"
                          rel="noreferrer"
                          className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                        >
                          <FaWhatsapp className="text-white" />
                        </a>
                        <IconShare post={post} />
                      </div>
                      <div className="text-card">
                        <Link
                          to={`/ofertas/${post.id}`}
                          className="text-black fw-semibold text-md-start"
                          style={{ marginBottom: "-2px" }}
                        >
                          {post.title}
                        </Link>
                        <div className="d-flex align-items-center mb-5">
                          <IoLocationSharp className="icon-color" />
                          <p
                            className="mb-0 mx-1 text-dark-emphasis fw-semibold text-gilroy-semibold"
                            style={{ fontSize: "13px" }}
                          >
                            {post.cidade}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
