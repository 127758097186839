import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRegStar, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import MainLayout from "../../../components/Layout/Main";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";
import PostModel from "../../../interfaces/models/post.model";
import {
  getFavorites,
  postSwapFavorite,
} from "../../../services/posts.service";
import { Filtro } from "../components/filtro";
import { Link, useNavigate } from "react-router-dom";
import { LuStarOff } from "react-icons/lu";

export default function PostsFavorites() {
  const [loading, setLoading] = useState<boolean>(false);
  const [posts, setPosts] = useState<PostModel[]>([]);

  async function fetchFavorites(): Promise<void> {
    setLoading(true);

    getFavorites()
      .then(({ data }) => {
        setPosts(data);
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar os favoritos.");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchFavorites();
  }, []);

  async function handleFavoritePost(postId: string): Promise<void> {
    setLoading(true);

    postSwapFavorite(postId)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        fetchFavorites();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao favoritar");
      })
      .finally(() => setLoading(false));
  }

  return (
    <MainLayout>
      <div className="container mw-100">
        <div className="row">
          <div className="col-md-3">
            <Filtro />
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12">
                <h4 className="mb-4 color-primary fw-semibold text-gilroy-semibold">
                  Meus favoritos
                </h4>
              </div>
            </div>

            <div className="row mb-4">
              {loading && <Loading center />}

              {!loading && posts.length === 0 && (
                <div className="col-12">
                  <div className="alert alert-warning">
                    Você ainda não tem favoritos.
                  </div>
                </div>
              )}

              {!loading &&
                posts.length > 0 &&
                posts.map((post, index) => (
                  <div
                    key={index}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 col-sm-6 mt-30 position-relative"
                  >
                    <div>
                      <div
                        className={`badge ${
                          posts
                            .map((favoritePosts) => favoritePosts.id)
                            .includes(post.id)
                            ? "bg-azul border-favorites"
                            : "bg-white border-favorites-null"
                        } position-absolute text-center`}
                      >
                        <div className="w-100" style={{ maxWidth: 150 }}>
                          <div className="d-flex align-items-center">
                            {posts
                              .map((favoritePost) => favoritePost.id)
                              .includes(post.id) ? (
                              <FaRegStar
                                style={{ fontSize: "25px" }}
                                id="favoritos"
                                className="cursor-pointer text-white"
                                onClick={() => handleFavoritePost(post.id)}
                              />
                            ) : (
                              <LuStarOff
                                style={{ color: "#C5CDE5", fontSize: "25px" }}
                                id="favoritos"
                                className="cursor-pointer"
                                onClick={() => handleFavoritePost(post.id)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {post.poster && (
                        <Link to={`/posts/${post.id}`}>
                          <img
                            src={post.poster}
                            className="img-poster"
                            alt="Imagem"
                          />
                        </Link>
                      )}
                      <img
                        src={`/images/categories/${post.category?.systemKey}_up.png`}
                        className="img-fluid mt-n1"
                        style={{ marginTop: "-20px" }}
                        alt={post.category?.title}
                      />
                      <div className="d-flex icons-category justify-content-end">
                        <a
                          href={`https://api.whatsapp.com/send?text=Veja o item ${post.title} - ${post.cidade} ${window.location.href} Baixe o IdadeAtiva para não perder nenhum item`}
                          target="_blank"
                          rel="noreferrer"
                          className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                        >
                          <FaWhatsapp className="text-white" />
                        </a>
                        <IconShare post={post} />
                      </div>
                      <div className="text-card mt-2">
                        <p className="fw-semibold text-md-start text-gilroy-semibold" style={{ marginBottom: "-2px" }}>
                          {post.title}
                        </p>
                        <div className="d-flex align-items-center mb-5">
                          <IoLocationSharp className="icon-color" />
                          <p className="mb-0 mx-1 text-dark-emphasis fw-semibold text-gilroy-semibold" style={{ fontSize: "13px" }}>
                            {post.cidade}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
