import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkAccess } from "../../services/admin.service";
import HeaderAdmin from "../Header/Admin";
import { Loading } from "../Loading";

interface Props {
  children: React.ReactNode;
  background?: "white" | "transparent";
}

export default function AdminLayout({ children, background = "white" }: Props) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [hasAdminAccess, setHasAdminAccess] = useState<boolean>(false);

  async function checkIfUserHasAdminAccess(): Promise<void> {
    checkAccess()
      .then(() => {
        setHasAdminAccess(true);
      })
      .catch(() => {
        setHasAdminAccess(false);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    checkIfUserHasAdminAccess();
  }, []);

  return (
    <>
      {loading && (
        <div className="min-vh-100 d-flex justify-content-center align-items-center">
          <Loading center color="white" />
        </div>
      )}

      {!loading && hasAdminAccess && (
        <>
          <HeaderAdmin />
          <div
            className={clsx(
              "pt-5",
              background === "transparent" && "bg-transparent",
              background === "white" && "bg-white",
            )}
          >
            <div
              className={clsx(
                "pb-5 px-3 px-lg-4 min-vh-100",
                background === "transparent" && "bg-transparent",
                background === "white" && "mt-5 bg-white",
              )}
            >
              {children}
            </div>
          </div>
        </>
      )}

      {!loading && !hasAdminAccess && (
        <div className="min-vh-100 p-3 d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-white text-center mb-4">
            Você não tem permissão para acessar esta página
          </h1>

          <button
            onClick={() => navigate(-1)}
            className="btn btn-danger mx-1 mb-1"
          >
            Voltar
          </button>
        </div>
      )}
    </>
  );
}
