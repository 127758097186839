import { ReactNode, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useCheckAuth } from "../hooks/useCheckAuth";

type PrivateRouteProps = {
  children: ReactNode;
};

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const navigate = useNavigate();

  const isAuth: boolean = useCheckAuth();

  useEffect(() => {
    if (!isAuth) {
      navigate("/login", { replace: true });
      /*toast.error("Você deve realizar o login para acessar essa página.");*/
    }
  }, [isAuth, navigate]);

  return (
    <>
      {!isAuth && <Navigate to="/login" replace />}
      {isAuth && children}
    </>
  );
}
