import { FaArrowRight, FaWhatsapp } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  getCategories,
  getFavorites,
  getPostsByCategory,
  postSwapFavorite,
} from "../../../services/posts.service";
import CategoryModel from "../../../interfaces/models/category.model";
import toast from "react-hot-toast";
import PostModel from "../../../interfaces/models/post.model";
import { Loading } from "../../../components/Loading";
import NoItemsFound from "../../../components/no-items-found";
import IconShare from "../../../components/share";
import { useCheckAuth } from "../../../hooks/useCheckAuth";

export const Categorias = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>(
    "00000000-0000-0000-0000-000000000000"
  );
  const [filteredPosts, setFilteredPosts] = useState<PostModel[]>([]);

  const isAuth: boolean = useCheckAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  const [postsFavorites, setPostFavorites] = useState<PostModel[]>([]);

  function handleFilterClick(c: CategoryModel) {
    setSelectedCategory(c.id);
    fetchPosts(c.id);
  }

  async function fetchPosts(categoryId?: string): Promise<void> {
    setLoading(true);

    getPostsByCategory(queryString, categoryId)
      .then(({ data }) => {
        setPosts(data.slice(0, 8));
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  async function fetchFilter(): Promise<void> {
    setLoading(true);

    getCategories()
      .then(({ data }) => {
        setCategories(data);
      })
      .catch((err) => {
        toast.error(err.response.data[0]);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchFilter();
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  useEffect(() => {
    const storedSearchString = localStorage.getItem("searchString");

    if (storedSearchString) {
      const filtered = posts.filter((post) =>
        post.title?.toLowerCase().includes(storedSearchString.toLowerCase())
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(posts);
    }
  }, [posts]);

  async function fetchFavorites(): Promise<void> {
    setLoading(true);

    getFavorites()
      .then(({ data }) => {
        setPostFavorites(data);
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar os favoritos.");
      })
      .finally(() => setLoading(false));
  }

  async function handleFavoritePost(postId: string): Promise<void> {
    setLoading(true);

    postSwapFavorite(postId)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        fetchFavorites();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao favoritar");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (isAuth) {
      fetchFavorites();
    }
  }, []);

  return (
    <div className="categorias">
      <div className="align-items-center flex-column flex-md-row mb-4 text-center text-md-start">
        <h4 className="color-primary fw-semibold text-gilroy-semibold">
          Por categoria
        </h4>
        {/*<p className="color-secondary fw-semibold small-text mb-0 text-gilroy-semibold text-subtitle">
          Veja as postagens por categoria
  </p>*/}
      </div>

      <div className="row mb-4 text-center text-md-start">
        <div className="col-12">
          {categories.map((c) => (
            <button
              key={c.id}
              className="badge--category"
              onClick={() => handleFilterClick(c)}
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <img
                src={`/images/categories/${c.systemKey}_pill_inverted.svg`}
                alt={c.title}
              />
            </button>
          ))}
        </div>
      </div>

      <div className="row">
        {loading && <Loading center />}

        {!loading && filteredPosts.length === 0 && (
          <p className="color-secondary text-gilroy-semibold h5 text-center text-md-start">
            Em breve.
          </p>
        )}

        {/*!loading && posts.length === 0 && <NoItemsFound />*/}

        {!loading &&
          filteredPosts.map((post) => (
            <div key={post.id} className="col-12 mb-4 position-relative">
              <div className="d-flex align-items-center flex-wrap rounded mb-4 category-card">
                <Link to={`/posts/${post.id}`} className="m-3">
                  <img
                    src={post.poster}
                    alt="Imagem"
                    className="rounded card-category"
                    height="60"
                  />
                </Link>
                <div className="col-8 d-flex align-items-center">
                  <div className="fw-semibold col-10 mx-0 mb-0 mx-lg-3 mb-lg-3 mx-xl-0 mb-xl-0 align-items-center">
                    <Link
                      to={`/posts/${post.id}`}
                      className="text-black mb-0 text-gilroy-semibold"
                    >
                      {post.title}
                    </Link>
                    <div
                      className="d-flex align-items-center"
                      style={{ fontSize: "13px" }}
                    >
                      <IoLocationSharp className="icon-color" />
                      <p className="mb-0 mx-1 text-dark-emphasis fw-semibold text-gilroy-semibold">
                        {post.cidade}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row badge-categories-config">
                  <div className="form-check form-switch d-flex align-items-center">
                    <input
                      className="form-check-input mb-1 cursor-pointer"
                      style={{ width: "3.3em", height: "1.7em" }}
                      type="checkbox"
                      role="switch"
                      id="favoritos"
                      onChange={() => handleFavoritePost(post.id)}
                      checked={postsFavorites
                        .map((post) => post.id)
                        .includes(post.id)}
                      disabled={loading}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex icons-category justify-content-end">
                <a
                  href={`https://api.whatsapp.com/send?text=Veja o item ${post.title} - ${post.cidade} ${window.location.href} Baixe o IdadeAtiva para não perder nenhum item`}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                >
                  <FaWhatsapp className="text-white" />
                </a>
                <IconShare post={post} />
              </div>
            </div>
          ))}
        {/* Button */}
        <div className="col-12 mt-2 mb-4">
          <Link
            to={`/posts/category/${selectedCategory}`}
            className="btn btn-seeall w-100 rounded text-white text-center fw-semibold justify-content-center d-flex align-items-center position-relative text-gilroy-semibold"
          >
            Ver todas
            <span>
              <FaArrowRight className="arrow-icon" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
