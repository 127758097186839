interface Props {
  center?: boolean;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "black"
    | "white"
    | "#183754"
}

export const Loading = ({ center, color = "#183754" }: Props) => {
  return (
    <>
      {center ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className={`spinner-border text-${color}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className={`spinner-border text-${color}`} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </>
  );
};
