import MainLayout from "../../components/Layout/Main";
import { Categorias } from "./components/categorias";
import { Filtro } from "./components/filtro";
import { OfertasDestaque } from "./components/ofertas-destaque";
import { OfertasRecentes } from "./components/ofertas-recentes";

export default function Posts() {
  return (
    <MainLayout>
      <div className="container mw-100">
        <div className="row">
          <div className="col-md-3">
            <Filtro />
          </div>

          <div className="col-md-9">
            <OfertasDestaque />

            <div className="row">
              <div className="col-12 col-lg-6">
                <OfertasRecentes />
              </div>

              <div className="col-md-12 col-lg-6">
                <Categorias />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
