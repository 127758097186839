import Footer from "../Footer";

interface Props {
  children: React.ReactNode;
  title: string;
}

export default function CleanLayout({ children, title }: Props) {
  return (
    <div
      className="d-flex flex-column justify-content-between align-items-center p-3 min-vh-100"
      style={{ background: "url(/images/background.png)", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
    >
      <div
        className="w-100 h-100 py-5"
        style={{ maxWidth: 880, borderRadius: 20, background: "#183754" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center px-3 mb-4">
          <img
            className="mb-4"
            src={`/images/logo/idadeativa.png`}
            alt="logo"
            width="170"
          />
          <h3
            className="text-center text-gilroy-semibold text-white"
            style={{
              fontSize: 25,
              fontWeight: "semibold",
              fontFamily: "gilroy-semibold !important",
            }}
          >
            {title}
          </h3>
        </div>

        <div className="divider mb-4"></div>

        <div className="d-flex flex-column align-items-center px-3 px-md-5 mx-md-5 text-gilroy-semibold">
          {children}
        </div>

        <div className="divider mt-4"></div>

        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center px-3 mt-4">
          <a
            className="me-0 me-sm-3 mb-3 mb-sm-0"
            id="appleStoreUrl"
            href="https://apps.apple.com/us/app/idadeativa/id1533361183"
            data-href-web="https://apps.apple.com/us/app/idadeativa/id1533361183"
            data-href-mobile="itms-apps://apple.com/app/id1533361183"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="apple-store"
              height="49"
              width="178"
              src="/images/apple-store.png"
              alt="apple store"
            />
          </a>

          <a
            id="googleStoreUrl"
            href="https://play.google.com/store/apps/details?id=me.idadeativa"
            data-href-web="https://play.google.com/store/apps/details?id=me.idadeativa"
            data-href-mobile="market://details?id=me.idadeativa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              height="49"
              width="178"
              src="/images/google-store.png"
              alt="google store"
            />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}
