import LoginArgs from "../interfaces/args/login.args";
import PasswordResetArgs from "../interfaces/args/password-reset.args";
import RecoverArgs from "../interfaces/args/recover.args";
import RegisterArgs from "../interfaces/args/register.args";
import SocialSignInArgs from "../interfaces/args/social-sign-in.args";
import AuthModel from "../interfaces/models/auth.model";
import api from "./client/api-client";

export const login = async (args: LoginArgs) =>
  api.post<AuthModel>("/login", args);

export const register = async (args: RegisterArgs) =>
  api.post("/register", args);

export const socialSignIn = async (args: SocialSignInArgs) =>
  api.post("/SocialSignIn", args);

export const forgotPassword = async (args: RecoverArgs) =>
  api.post("/account/recover", args);

export const resetPassword = async (token: string, args: PasswordResetArgs) =>
  api.post(`/account/passwordreset/${token}`, args);
