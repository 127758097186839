import { IoLocationSharp } from "react-icons/io5";
import { FaArrowRight, FaRegStar, FaWhatsapp } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import PostModel from "../../../interfaces/models/post.model";
import {
  getFavorites,
  getPostsNext,
  postSwapFavorite,
} from "../../../services/posts.service";
import { Loading } from "../../../components/Loading";
import IconShare from "../../../components/share";
import toast from "react-hot-toast";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import { LuStarOff } from "react-icons/lu";

export const OfertasRecentes = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const isAuth: boolean = useCheckAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const queryString = searchParams.get("query");

  const [posts, setPosts] = useState<PostModel[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<PostModel[]>([]);
  const searchTerm = localStorage.getItem("searchTerm") || "";

  const [postsFavorites, setPostFavorites] = useState<PostModel[]>([]);

  const localStorageKey = "recentsPosts";
  const localStorageFavoritesKey = "recentsFavoritesPosts";

  const saveToLocalStorageFeatured = (data: PostModel[]) => {
    localStorage.setItem(localStorageKey, JSON.stringify(data));
  };

  const saveToLocalStorageFavorites = (data: PostModel[]) => {
    localStorage.setItem(localStorageFavoritesKey, JSON.stringify(data));
  };

  const loadFromLocalStorageFeatured = (): PostModel[] => {
    const data = localStorage.getItem(localStorageKey);
    return data ? JSON.parse(data) : [];
  };

  const loadFromLocalStorageFavorites = (): PostModel[] => {
    const data = localStorage.getItem(localStorageFavoritesKey);
    return data ? JSON.parse(data) : [];
  };

  async function fetchPosts(): Promise<void> {
    setLoading(true);

    getPostsNext(queryString)
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorageFeatured();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorageFeatured(fetchedPosts);
          setPosts(fetchedPosts);
        } else {
          setPosts(storedPosts);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }

  async function fetchFavorites(): Promise<void> {
    setLoading(true);

    getFavorites()
      .then((res) => {
        const fetchedPosts = res.data;
        const storedPosts = loadFromLocalStorageFavorites();

        const isNewData =
          JSON.stringify(fetchedPosts) !== JSON.stringify(storedPosts);

        if (isNewData) {
          saveToLocalStorageFavorites(fetchedPosts);
          setPostFavorites(fetchedPosts);
        } else {
          setPostFavorites(storedPosts);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao carregar os favoritos.");
      })
      .finally(() => setLoading(false));
  }

  async function handleFavoritePost(postId: string): Promise<void> {
    setLoading(true);

    postSwapFavorite(postId)
      .then(() => {
        toast.success("Atualizado com sucesso!");
        fetchFavorites();
      })
      .catch((err) => {
        toast.error(err?.response?.data[0] ?? "Erro ao favoritar");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    const storedPosts = loadFromLocalStorageFeatured();
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
    }

    fetchPosts();
  }, [queryString]);

  useEffect(() => {
    if (isAuth) {
      const storedPosts = loadFromLocalStorageFavorites();
      if (storedPosts.length > 0) {
        setPostFavorites(storedPosts);
      }

      fetchFavorites();
    }
  }, []);

  useEffect(() => {
    const storedSearchString = localStorage.getItem("searchString");

    if (storedSearchString) {
      const filtered = posts.filter((post) =>
        post.title?.toLowerCase().includes(storedSearchString.toLowerCase())
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(posts);
    }
  }, [posts]);

  return (
    <div className="ofertas-recentes">
      <div className="align-items-center flex-column flex-md-row mb-5 text-center text-md-start">
        <h4 className="color-primary fw-semibold text-gilroy-semibold">
          Populares do mês
        </h4>
        {/*<p className="color-secondary fw-semibold small-text mb-0 text-gilroy-semibold text-subtitle">
          Postagens adicionadas nos últimos 30 dias
  </p>*/}
      </div>

      <div className="row mb-4">
        {loading && <Loading center />}

        {!loading && filteredPosts.length === 0 && (
          <p className="color-secondary text-gilroy-semibold h5 text-center text-md-start">
            Em breve.
          </p>
        )}

        {!loading &&
          filteredPosts.map((post) => (
            <div
              key={post.id}
              className="col-6 col-md-4 col-lg-4 col-sm-6 mb-5 position-relative"
            >
              <div
                className={`badge ${
                  postsFavorites
                    .map((favoritePosts) => favoritePosts.id)
                    .includes(post.id)
                    ? "bg-azul border-favorites"
                    : "bg-white border-favorites-null"
                } position-absolute text-center`}
              >
                <div className="w-100" style={{ maxWidth: 150 }}>
                  <div className="d-flex align-items-center">
                    {postsFavorites
                      .map((favoritePost) => favoritePost.id)
                      .includes(post.id) ? (
                      <FaRegStar
                        style={{ fontSize: "25px" }}
                        id="favoritos"
                        className="cursor-pointer text-white"
                        onClick={() => handleFavoritePost(post.id)}
                      />
                    ) : (
                      <LuStarOff
                        style={{ color: "#C5CDE5", fontSize: "25px" }}
                        id="favoritos"
                        className="cursor-pointer"
                        onClick={() => handleFavoritePost(post.id)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <Link to={`/posts/${post.id}`}>
                <img src={post.poster} className="cards-hilight" alt="Imagem" />
              </Link>
              <img
                src={`/images/categories/${post.category?.systemKey}_up.png`}
                className="img-fluid mt-n1"
                style={{ marginTop: "-20px" }}
                alt={post.category?.title}
              />
              <div className="text-card">
                <Link
                  to={`/posts/${post.id}`}
                  className="text-black mb-1 fw-semibold text-center text-md-start text-gilroy-semibold"
                >
                  {post.title}
                </Link>
                <div
                  className="d-flex align-items-center mb-2"
                  style={{ fontSize: "13px" }}
                >
                  <IoLocationSharp className="icon-color" />
                  <p className="mb-0 mx-1 text-dark-emphasis fw-semibold text-gilroy-semibold text-gilroy-semibold">
                    {post.cidade}
                  </p>
                </div>
                <div className="border mb-2"></div>
                <div className="d-flex align-items-center justify-content-center">
                  <a
                    href={`https://api.whatsapp.com/send?text=Veja o item ${post.title} - ${post.cidade} ${window.location.href} Baixe o IdadeAtiva para não perder nenhum item`}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-whatsapp shadow d-flex justify-content-center align-items-center rounded-circle p-2 ms-2"
                  >
                    <FaWhatsapp className="text-white" />
                  </a>
                  <IconShare post={post} />
                </div>
              </div>
            </div>
          ))}

        {/* Button */}
        <div className="col-12 mt-2">
          <Link
            to="/posts/recent"
            className="btn btn-seeall w-100 rounded text-white text-center fw-semibold justify-content-center d-flex align-items-center position-relative text-gilroy-semibold"
          >
            Ver todas
            <span>
              <FaArrowRight className="arrow-icon" />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
